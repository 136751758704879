import { useEffect, useState, useMemo } from 'react';

import type { Card, LinkCardsParameters } from '../linkCardsTypes';

import { useSearchPageResults } from './useSearchPageResults';

export const useCards = (parameters: LinkCardsParameters) => {
	const { cardCount, filters } = parameters;
	const [cards, setCards] = useState<Card[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	const { getDynamicCards } = useSearchPageResults();

	useEffect(() => {
		if (!!cardCount && !!filters) {
			void getDynamicCards(cardCount, filters)
				.then(({ cards: dynamicCards, errors: aggErrors }) => {
					if (aggErrors) {
						setError(new Error(JSON.stringify(aggErrors)));
					}
					setCards(dynamicCards);
				})
				.catch((err) => {
					setError(err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [getDynamicCards, cardCount, filters]);

	return useMemo(() => ({ cards, loading, error }), [cards, loading, error]);
};
