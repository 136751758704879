import React, { useEffect, useCallback } from 'react';
import type { FC } from 'react';

import {
	CustomBlock,
	ElementName,
	MetadataBlock,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	SnippetBlock,
	TitleBlock,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';

import { CardSizes } from '../../linkCardsTypes';
import { EmojiRenderer } from '../EmojiRenderer';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';

import {
	CommonCardWrapperStyles,
	floatingEmojiStyles,
	getTitleBlockStyles,
	getCustomImageUploadStyles,
	MediumLargeCardWrapper as CardWrapper,
} from './cardComponentsStyles';
import { CardErrorState } from './ErrorStates';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { CommonCardProps } from './commonCardProps';

export const MediumCard: FC<CommonCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	isAvatarShown,
	isPublishDateShown,
	description,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	imageAltText,
	createAnalyticsEvent,
	...analyticsPassThroughProps
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const onResolve = useCallback(() => {
		trackSuccess();
	}, [trackSuccess]);

	const { emojiId } = useSmartCardEmoji(link);
	const { showUnsplashImage, showExternalSiteImage, showCustomUploadedImage, hasImage } =
		getImageTypeToShow({
			imageSrc,
		});

	useEffect(() => {
		onResolve();
	}, [onResolve]);

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.MEDIUM}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				{...analyticsPassThroughProps}
			/>
		);

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<CardWrapper cardHeight={cardHeight} hasImage={hasImage}>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isInViewMode,
						size: SmartLinkSize.Medium,
					}}
					url={link}
					onResolve={onResolve}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock ignoreContainerPadding overrideUrl={imageSrc} />
					)}
					{showCustomUploadedImage && (
						<CustomBlock overrideCss={getCustomImageUploadStyles() as any}>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock
						overrideCss={floatingEmojiStyles as any}
						testId="smart-block-link-card-emoji-container"
					>
						<EmojiRenderer emojiId={emojiId} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.AuthorGroup }] : []}
						text={title}
						anchorTarget="_self"
						overrideCss={getTitleBlockStyles({ isUnauthorized }) as any}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && <SnippetBlock text={description} />}
				</SmartCardSSR>
			</CardWrapper>
		</CommonCardWrapperStyles>
	);
};
