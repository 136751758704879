import { useCallback, useMemo } from 'react';

import type {
	EntityATI,
	SearchPageQueryVariables,
	SearchConfluencePartial,
} from '@atlassian/dynamic-cards-search-client';
import {
	searchPageQuery,
	PAGE_ATI,
	BLOGPOST_ATI,
	SORT_FIELD_KEY,
	SORT_ORDER,
} from '@atlassian/dynamic-cards-search-client';
import {
	getConfluenceDateOptionValues,
	getConfluenceDateOptionFromCustomDates,
} from '@atlassian/dynamic-cards-search-page';

import { useSearchSessionId } from '@confluence/search-session';
import { useSessionData } from '@confluence/session-data';

import { createCard } from '../../link-cards/edit/LinkCardsManifest';
import { createNewCardWithDefaultParameters } from '../../shared-components/ConfigPanel';
import {
	propertyToId,
	CoverPictureIdPropertyKey,
	isUnsplashImage,
} from '../../shared-components/UnsplashSearch';
import type { Filters } from '../linkCardsTypes';

// TODO: create utils file to group below methods to sanitize and convert filters into searchPageQuery variables
const getFilterValue = (filter?: string) => {
	return filter?.split(',') || [];
};

const getFilterDateRange = (filters: Filters) => {
	const { lastModified } = filters;
	if (lastModified && lastModified !== 'custom') {
		return [getConfluenceDateOptionValues(lastModified)];
	} else if (lastModified === 'custom') {
		if (filters?.from && filters?.to) {
			return [getConfluenceDateOptionFromCustomDates(filters.from, filters.to)];
		}
	}
	return [];
};

// TODO: handle the accuracy of getEntities in https://hello.jira.atlassian.cloud/browse/COMPHUB-2224
const getEntities = (type?: string) => {
	if (type === 'page') {
		return [PAGE_ATI];
	} else if (type === 'blogpost') {
		return [BLOGPOST_ATI];
	}
	return [PAGE_ATI, BLOGPOST_ATI];
};

const getConfluenceFilters = (filters: Filters) => {
	const { spaces, owners, labels, contributors, ancestor, verified } = filters;

	return {
		spacesFilter: getFilterValue(spaces),
		isVerified: verified || undefined,
		contributorsFilter: getFilterValue(contributors),
		ancestorIdsFilter: getFilterValue(ancestor),
		range: getFilterDateRange(filters),
		owners: getFilterValue(owners),
		labelsFilter: getFilterValue(labels),
	};
};

const getSearchPageQueryVariables = (
	cardCount: number,
	filters: Filters,
	cloudId: string,
	searchSessionId: string | null | undefined,
) => {
	const entities = getEntities(filters.type) as EntityATI[];

	return {
		query: '',
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: cardCount,
		entities,
		commonFilters: {},
		confluenceFilters: getConfluenceFilters(filters),
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
			},
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		queryVersion: 1,
		searchSessionId: searchSessionId ?? '',
		sort: [
			{
				field: SORT_FIELD_KEY.LAST_MODIFIED_DATE,
				order: SORT_ORDER.DESC,
			},
		],
	};
};

export const useSearchPageResults = () => {
	const { cloudId } = useSessionData();
	const [{ searchSessionId }] = useSearchSessionId();

	const getDynamicCards = useCallback(
		async (cardCount: number, filters: Filters) => {
			const variables = getSearchPageQueryVariables(
				cardCount,
				filters,
				cloudId,
				searchSessionId,
			) satisfies SearchPageQueryVariables;

			const { data, errors } = await searchPageQuery({ variables });

			const results =
				data?.search.results.edges.map((edge) => edge.node as SearchConfluencePartial) || [];

			if (results.length === 0 || errors) {
				const loadingCards = new Array(3).fill(0).map(() => {
					const newEmptyCard = createCard('', '');

					return newEmptyCard;
				});

				return { cards: loadingCards, errors };
			}

			const trimmedResults = results?.slice(0, Math.min(cardCount, results.length));

			const dynamicCards = trimmedResults?.map((result) => {
				const { title, description, url, id, confluenceEntity } = result;

				const newCard = createNewCardWithDefaultParameters();
				newCard.link = url;
				newCard.title = title;
				newCard.description = description;
				newCard.confluencePageId = id.split('/')[1];

				if (confluenceEntity) {
					const { subtype, properties } = confluenceEntity;

					const isLivePage = subtype === 'live';
					let imageProperty;

					if (isLivePage) {
						imageProperty = properties?.find(
							(image) => image?.key === CoverPictureIdPropertyKey.DRAFT,
						);
					} else {
						imageProperty = properties?.find(
							(image) => image?.key === CoverPictureIdPropertyKey.PUBLISHED,
						);
						if (!imageProperty) {
							imageProperty = properties?.find(
								(image) => image?.key === CoverPictureIdPropertyKey.DRAFT,
							);
						}
					}

					let imageSrc = propertyToId(imageProperty)?.value?.id;
					if (imageSrc && !isUnsplashImage(imageSrc)) {
						imageSrc = `${newCard.confluencePageId}/${imageSrc}`;
					}
					newCard.imageSrc = imageSrc;
				}

				return newCard;
			});

			return { cards: dynamicCards, errors };
		},
		[cloudId, searchSessionId],
	);

	return useMemo(() => ({ getDynamicCards }), [getDynamicCards]);
};
