import type { EditorActions } from '@atlaskit/editor-core';

import type { Card, LinkCardsParameters } from '../../link-cards/linkCardsTypes';

import type { CustomSitesExtensionTypes } from './ConfigPanelContent';

export type UpdateField<T> = Partial<T>;

export type UseExtensionParametersAndActionsProps = {
	localId: string;
	editorActions: EditorActions;
	extensionType: CustomSitesExtensionTypes;
};

export type UseExtensionParametersAndActionsResponse = {
	extensionParameters: LinkCardsParameters;
	extensionActions: ExtensionActions;
};

export type AddCardType = () => void;
export type RemoveCardType = (cardId: string) => void;
export type UpdateMainFormFieldType = (
	updatedField: UpdateField<LinkCardsParameters>,
	options?: { fieldsToRemove?: string[] },
) => void;
export type UpdateCardFormFieldType = (cardId: string, updatedField: UpdateField<Card>) => void;

export type ExtensionActions = {
	addCard: AddCardType;
	removeCard: RemoveCardType;
	updateMainFormField: UpdateMainFormFieldType;
	updateCardFormField: UpdateCardFormFieldType;
};

export enum CARD_FIELDS {
	CARD_ID = 'cardId',
	TITLE = 'title',
	IS_TITLE_USER_EDITED = 'isTitleUserEdited',
	DESCRIPTION = 'description',
	IS_DESCRIPTION_USER_EDITED = 'isDescriptionUserEdited',
	LINK = 'link',
	IS_LINK_USER_EDITED = 'isLinkUserEdited',
	IMAGE_SRC = 'imageSrc',
	CONFLUENCE_PAGE_ID = 'confluencePageId',
	IMAGE_ALT_TEXT = 'imageAltText',
}

export enum CONDITIONAL_FIELDS {
	IS_AVATAR_SHOWN = 'isAvatarShown',
	IS_PUBLISH_DATE_SHOWN = 'isPublishDateShown',
	IS_SEGMENTATION_SHOWN = 'isSegmentationShown',
}
