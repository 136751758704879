import React, { type FC, type ReactNode, useEffect, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { useCustomSitesExtensions } from './useCustomSitesExtensions';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CardSelectedToEditWrapperStyles = styled.div<{
	isSelectedToEdit: boolean;
	isSelectedToRemove: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-smart-link-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
		...({ isSelectedToEdit }) =>
			isSelectedToEdit && {
				outline: `2px solid ${token('color.border.focused')}`,
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
		...({ isSelectedToRemove }) =>
			isSelectedToRemove && {
				outline: `2px solid ${token('color.border.danger')}`,
			},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-smart-link-container]::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
		...({ isSelectedToRemove }) =>
			isSelectedToRemove && {
				content: '',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 1,
				opacity: 0.5,
				backgroundColor: `${token('color.background.danger')}`,
			},
	},
});

type CardSelectedToEditWrapperProps = {
	extensionLocalId: string;
	cardId: string;
	children: ReactNode;
};

export const CardSelectedToEditWrapper: FC<CardSelectedToEditWrapperProps> = ({
	extensionLocalId,
	cardId,
	children,
}) => {
	const cardRef = useRef<HTMLDivElement>(null);
	const [
		{ selectedExtensionLocalId, selectedToEditCardId, selectedToRemoveCardId },
		{ setIsInAutoFocusMode, setSelectedToEditCardId },
	] = useCustomSitesExtensions();

	useEffect(() => {
		if (
			selectedToEditCardId &&
			selectedToEditCardId === cardId &&
			extensionLocalId === selectedExtensionLocalId &&
			cardRef.current
		) {
			cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [cardId, extensionLocalId, selectedExtensionLocalId, selectedToEditCardId, cardRef]);

	const onCardClick = useCallback(() => {
		if (extensionLocalId === selectedExtensionLocalId) {
			setIsInAutoFocusMode(true);
			setSelectedToEditCardId(cardId);
		}
	}, [
		cardId,
		extensionLocalId,
		selectedExtensionLocalId,
		setSelectedToEditCardId,
		setIsInAutoFocusMode,
	]);

	const isSelectedToEdit =
		selectedToEditCardId === cardId && extensionLocalId === selectedExtensionLocalId;
	const isSelectedToRemove =
		selectedToRemoveCardId === cardId && extensionLocalId === selectedExtensionLocalId;

	return (
		<CardSelectedToEditWrapperStyles
			ref={cardRef}
			isSelectedToEdit={isSelectedToEdit}
			isSelectedToRemove={isSelectedToRemove}
			onClick={onCardClick}
		>
			{children}
		</CardSelectedToEditWrapperStyles>
	);
};
