import type { NetworkError } from '@confluence/network';
import { cfetch } from '@confluence/network';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

export type UnsplashResult = {
	id: string;
	altDescription: string;
	smallSizeImageUrl: string;
	fullSizeImageUrl: string;
	artistName: string;
	artistUrl: string;
	downloadLink: string;
};
export const REFERRAL_LINK = '?utm_source=Confluence&utm_medium=referral';
export const UNSPLASH_URL = 'images.unsplash.com';

export const isUnsplashImage = (imageUrl?: string): boolean => {
	if (!imageUrl) {
		return false;
	}
	return imageUrl?.includes(UNSPLASH_URL);
};

export const getUnsplashPhotos = async (
	queryWord: string,
	pageNumber: number,
	isPageTitleComponent?: boolean,
) => {
	return cfetch(
		`/wiki/rest/internals/1.0/unsplash/search?query=${queryWord}&pageNumber=${pageNumber}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	)
		.then((response) => response.json())
		.then((responseData) => {
			return responseData.results?.map((result) => ({
				id: result.id,
				altDescription: result.alt_description,
				smallSizeImageUrl: result.urls.thumb,
				fullSizeImageUrl: isPageTitleComponent ? result.urls.full : result.urls.regular,
				artistName: result.user.name,
				artistUrl: result.user.links.html + REFERRAL_LINK,
				downloadLink: result.links.download_location,
			}));
		})
		.catch((error: NetworkError) => {
			// sometimes the api returns a error object
			// especially on staging
			const err = typeof error === 'object' ? JSON.stringify(error) : error;

			getMonitoringClient().submitError(err, {
				attribution: Attribution.COMPANY_HUB,
			});

			throw error;
		});
};
