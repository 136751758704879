import React, { type FC } from 'react';

import { FormattedMessage } from 'react-intl-next';

import Icon, { type CustomGlyphProps, type Size } from '@atlaskit/icon';
import {
	AtlassianIcon,
	BitbucketIcon,
	CompassIcon,
	ConfluenceIcon,
	JiraIcon,
	JiraProductDiscoveryIcon,
	JiraServiceManagementIcon,
	type LogoProps,
	TrelloIcon,
} from '@atlaskit/logo';

// This is the only place we want to import this
import { DO_NOT_IMPORT_ProductKeys3P, DO_NOT_IMPORT_ProductsOrder3P } from './3p-product-configs';
import { AirtableIcon } from './icons/airtable';
import { AirtableFilterIcon } from './icons/airtable-filter';
import { AsanaIcon } from './icons/asana';
import { AtlasSearchResultIcon } from './icons/atlas-search';
import { ConfluenceSearchResultIcon } from './icons/confluence-search';
import { DropboxIcon } from './icons/dropbox';
import { FigmaIcon } from './icons/figma';
import { FigmaSearchResultIcon } from './icons/figma-search';
import { GithubIcon } from './icons/github';
import { GithubSearchResultIcon } from './icons/github-search';
import { GmailIcon } from './icons/gmail';
import { GoogleCalendarIcon } from './icons/google-calendar';
import { GoogleDriveIcon } from './icons/google-drive';
import { GoogleDriveSearchResultIcon } from './icons/google-drive-search';
import { JiraSearchResultIcon } from './icons/jira-search';
import { LoomIcon } from './icons/loom';
import { MiroIcon } from './icons/miro';
import { MiroFilterIcon } from './icons/miro-filter';
import { NotionIcon } from './icons/notion';
import { NotionFilterIcon } from './icons/notion-filter';
import { OneDriveIcon } from './icons/onedrive';
import { OutlookCalendarIcon } from './icons/outlook-calendar';
import { SharePointIcon } from './icons/sharepoint';
import { SharePointSearchResultIcon } from './icons/sharepoint-search';
import { SlackIcon } from './icons/slack';
import { SlackSearchResultIcon } from './icons/slack-search';
import { TeamsIcon } from './icons/teams';
import { TeamsSearchResultIcon } from './icons/teams-search';
import { WebDomainIcon } from './icons/web-domain';
import { WebDomainSearchResultIcon } from './icons/web-domain-search';
import { ZendeskIcon } from './icons/zendesk';
import { messages } from './messages';

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
	[Property in Key]-?: Type[Property];
};

export type IconProps = Omit<
	WithRequiredProperty<LogoProps, 'appearance'>,
	'iconColor' | 'textColor' | 'size'
> & {
	size?: SmallOrMedium;
};

export interface ProductConfig {
	displayName: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/ban-types
	Icon: FC<IconProps>;
	is3P?: boolean;
	// eslint-disable-next-line @typescript-eslint/ban-types
	SearchResultIcon?: FC<IconProps>;
}

const ProductKeys1P = {
	Atlas: 'townsquare',
	Bitbucket: 'bitbucket',
	Compass: 'compass',
	Confluence: 'confluence',
	Jira: 'jira',
	JiraProductDiscovery: 'jira-product-discovery',
	JiraServiceManagement: 'jira-servicedesk',
	Opsgenie: 'opsgenie',
	Trello: 'trello',
} as const;

export const ProductKeys3P = DO_NOT_IMPORT_ProductKeys3P;

export const ProductKeys = {
	...ProductKeys1P,
	...DO_NOT_IMPORT_ProductKeys3P,
} as const;

export type ProductKeys3P =
	(typeof DO_NOT_IMPORT_ProductKeys3P)[keyof typeof DO_NOT_IMPORT_ProductKeys3P];
export type ProductKeys1P = (typeof ProductKeys1P)[keyof typeof ProductKeys1P];
export type ProductKeys = (typeof ProductKeys)[keyof typeof ProductKeys];
export const allProductKeys: ProductKeys[] = Object.values(ProductKeys);
export const all3pProductKeys: ProductKeys3P[] = Object.values(DO_NOT_IMPORT_ProductKeys3P);
export const all1pProductKeys: ProductKeys1P[] = Object.values(ProductKeys1P);
export const is3pProductKey = (key: string): key is ProductKeys3P =>
	all3pProductKeys.includes(key as ProductKeys3P);

// This is different to ProductKeys, it represents products we have support for mounting Search Page in
export const PrimaryProductKeys = {
	// Fully supported as GA
	Atlas: 'townsquare',
	Confluence: 'confluence',
	// In development
	Jira: 'jira',
	Mercury: 'mercury',
	Elevate: 'elevate',
} as const;
export type PrimaryProductKey = (typeof PrimaryProductKeys)[keyof typeof PrimaryProductKeys];

/**
 * Due a bug in ADS custom icons are one size smaller
 * To accommodate for this behavior you need to use one size bigger than what you need
 * See: https://atlassian.slack.com/archives/CFJ9DU39U/p1714096323217799
 */
type SmallOrMedium = 'xsmall' | Exclude<Size, 'large' | 'xlarge'>;
type MediumOrLarge = Exclude<Size, 'xlarge'>;
const sizesCorrection: Record<SmallOrMedium, MediumOrLarge> = {
	xsmall: 'small',
	small: 'medium',
	medium: 'large',
};

export const glyphIcon =
	(glyph: (glyphProps: CustomGlyphProps) => JSX.Element, label = '') =>
	({ size = 'medium', ...props }: IconProps & { size?: SmallOrMedium }) => (
		<Icon label={label} {...props} glyph={glyph} size={sizesCorrection[size]} />
	);

/**
 * This is the source of truth for the products that are supported in Full Page Search.
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * QS-5444 3P references in this object are being deprecated. Please add to ./3p-product-configs
 */
export const products: Record<string, ProductConfig> = {
	[ProductKeys.Confluence]: {
		displayName: 'Confluence',
		Icon: ConfluenceIcon,
		SearchResultIcon: glyphIcon(ConfluenceSearchResultIcon, 'Confluence'),
	},
	[ProductKeys.Atlas]: {
		displayName: 'Atlas',
		Icon: AtlassianIcon,
		SearchResultIcon: glyphIcon(AtlasSearchResultIcon, 'Atlas'),
	},
	[ProductKeys.Jira]: {
		displayName: 'Jira',
		Icon: JiraIcon,
		SearchResultIcon: glyphIcon(JiraSearchResultIcon, 'Jira'),
	},
	[ProductKeys.JiraProductDiscovery]: {
		displayName: 'Jira Product Discovery',
		Icon: JiraProductDiscoveryIcon,
	},
	[ProductKeys.JiraServiceManagement]: {
		displayName: 'Jira Service Management',
		Icon: JiraServiceManagementIcon,
	},
	[ProductKeys.Bitbucket]: {
		displayName: 'Bitbucket',
		Icon: BitbucketIcon,
		SearchResultIcon: BitbucketIcon,
	},
	[ProductKeys.Trello]: {
		displayName: 'Trello',
		Icon: TrelloIcon,
	},
	// [ProductKeys.Opsgenie]: {
	// 	displayName: 'Opsgenie',
	// 	Icon: OpsgenieIcon,
	// },
	[ProductKeys.Compass]: {
		displayName: 'Compass',
		Icon: CompassIcon,
	},
	[ProductKeys.GoogleDrive]: {
		displayName: 'Google Drive',
		Icon: glyphIcon(GoogleDriveIcon, 'Google Drive'),
		is3P: true,
		SearchResultIcon: glyphIcon(GoogleDriveSearchResultIcon, 'Google Drive'),
	},
	[ProductKeys.Sharepoint]: {
		displayName: 'Microsoft SharePoint',
		Icon: glyphIcon(SharePointIcon, 'Sharepoint'),
		is3P: true,
		SearchResultIcon: glyphIcon(SharePointSearchResultIcon, 'Sharepoint'),
	},
	[ProductKeys.AtlassianWebCrawler]: {
		displayName: <FormattedMessage {...messages.webPage} />,
		Icon: glyphIcon(WebDomainIcon, 'Atlassian Web Crawler'),
		is3P: true,
		SearchResultIcon: glyphIcon(WebDomainSearchResultIcon, 'Atlassian Web Crawler'),
	},
	[ProductKeys.Teams]: {
		displayName: 'Teams',
		Icon: glyphIcon(TeamsIcon, 'Teams'),
		is3P: true,
		SearchResultIcon: glyphIcon(TeamsSearchResultIcon, 'Teams'),
	},
	[ProductKeys.Loom]: {
		displayName: 'Loom',
		Icon: glyphIcon(LoomIcon, 'Loom'),
		is3P: true,
	},
	[ProductKeys.OneDrive]: {
		displayName: 'OneDrive',
		Icon: glyphIcon(OneDriveIcon, 'OneDrive'),
		is3P: true,
	},
	[ProductKeys.Slack]: {
		displayName: 'Slack',
		Icon: glyphIcon(SlackIcon, 'Slack'),
		is3P: true,
		SearchResultIcon: glyphIcon(SlackSearchResultIcon, 'Slack'),
	},
	[ProductKeys.Github]: {
		displayName: 'GitHub',
		Icon: glyphIcon(GithubIcon, 'Github'),
		is3P: true,
		SearchResultIcon: glyphIcon(GithubSearchResultIcon, 'Github'),
	},
	[ProductKeys.Figma]: {
		displayName: 'Figma',
		Icon: glyphIcon(FigmaIcon, 'Figma'),
		is3P: true,
		SearchResultIcon: glyphIcon(FigmaSearchResultIcon, 'Figma'),
	},
	[ProductKeys.Dropbox]: {
		displayName: 'Dropbox',
		Icon: glyphIcon(DropboxIcon, 'Dropbox'),
		is3P: true,
	},
	[ProductKeys.GoogleCalendar]: {
		displayName: 'Google Calendar',
		Icon: glyphIcon(GoogleCalendarIcon, 'Google Calendar'),
		is3P: true,
	},
	[ProductKeys.Gmail]: {
		displayName: 'Gmail',
		Icon: glyphIcon(GmailIcon, 'Gmail'),
		is3P: true,
	},
	[ProductKeys.OutlookCalendar]: {
		displayName: 'Outlook Calendar',
		Icon: glyphIcon(OutlookCalendarIcon, 'Outlook Calendar'),
		is3P: true,
	},
	[ProductKeys.Airtable]: {
		displayName: 'Airtable',
		Icon: glyphIcon(AirtableFilterIcon, 'Airtable'),
		is3P: true,
		SearchResultIcon: glyphIcon(AirtableIcon, 'Airtable'),
	},
	[ProductKeys.Asana]: {
		displayName: 'Asana',
		Icon: glyphIcon(AsanaIcon, 'Asana'),
		is3P: true,
	},
	[ProductKeys.Zendesk]: {
		displayName: 'Zendesk',
		Icon: glyphIcon(ZendeskIcon, 'Zendesk'),
		is3P: true,
	},
	[ProductKeys.Notion]: {
		displayName: 'Notion',
		Icon: glyphIcon(NotionFilterIcon, 'Notion'),
		is3P: true,
		SearchResultIcon: glyphIcon(NotionIcon, 'Notion'),
	},
	[ProductKeys.Miro]: {
		displayName: 'Miro',
		Icon: glyphIcon(MiroFilterIcon, 'Miro'),
		is3P: true,
		SearchResultIcon: glyphIcon(MiroIcon, 'Miro'),
	},
};

export const PRODUCTS_ORDER: ProductKeys[] = [
	ProductKeys.Confluence,
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
	ProductKeys.Atlas,
	ProductKeys.Trello,
	ProductKeys.Bitbucket,
	ProductKeys.Opsgenie,
	ProductKeys.Compass,

	...DO_NOT_IMPORT_ProductsOrder3P,
];
