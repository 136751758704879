import React, { Fragment, memo, useEffect, type ReactNode } from 'react';
import isEqual from 'lodash/isEqual';

import type { ExtensionParams, Parameters } from '@atlaskit/editor-common/extensions';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { ExperienceTimeout } from '@confluence/experience-tracker';
import {
	MacroExperienceStart,
	getMacroAttributesFromADFNode,
	RENDERER,
	getExperienceName,
} from '@confluence/macro-tracker';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';

export type CustomSitesRenderedExtensionContainerProps<T extends Parameters> = {
	extensionNode: ExtensionParams<T>;
	contentId: string;
	children: ReactNode;
	analyticsAttributes?: { [key: string]: string | boolean | number };
};

const CustomSitesRenderedExtensionContainer = <T extends Parameters>({
	extensionNode,
	contentId,
	analyticsAttributes,
	children,
}: CustomSitesRenderedExtensionContainerProps<T>) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { extensionKey, parameters } = extensionNode;

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'viewed',
				actionSubject: 'macro',
				actionSubjectId: extensionKey,
				source: 'viewPageScreen',
				attributes: {
					...analyticsAttributes,
					hasParameters: !!parameters,
					pageLoadInfo: getUniquePageLoadId(),
				},
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const macroAttributes = getMacroAttributesFromADFNode(extensionNode);
	const experienceName = getExperienceName(RENDERER, extensionNode);

	return (
		<Fragment>
			<MacroExperienceStart
				name={experienceName}
				contentId={contentId}
				mode={RENDERER}
				extensionKey={extensionKey}
				attributes={macroAttributes}
				timeout={ExperienceTimeout.MACRO_LOAD}
			/>
			{children}
		</Fragment>
	);
};

export const CustomSitesRenderedExtension = memo(
	withErrorBoundary({
		attribution: Attribution.COMPANY_HUB,
	})(CustomSitesRenderedExtensionContainer),
	isEqual,
);
