import { type ThirdPartyConfig } from '../schemas/3p-config';

import { airtableConfig } from './airtable';
import { asanaConfig } from './asana';
import { atlassianWebCrawlerConfig } from './atlassian-web-crawler';
import { dropboxConfig } from './dropbox';
import { figmaConfig } from './figma';
import { githubConfig } from './github';
import { gmailConfig } from './gmail';
import { googleDriveConfig } from './google';
import { googleCalendarConfig } from './google-calendar';
import { loomConfig } from './loom';
import { miroConfig } from './miro';
import { notionConfig } from './notion';
import { oneDriveConfig } from './onedrive';
import { outlookCalendarConfig } from './outlook-calendar';
import { sharepointConfig } from './sharepoint';
import { slackConfig } from './slack';
import { teamsConfig } from './teams';
import { zendeskConfig } from './zendesk';
// New 3P? Add it here

const ProductKeys3P = {
	AtlassianWebCrawler: 'atlassian-web-crawler',
	Figma: 'figma',
	Github: 'github',
	GoogleDrive: 'drive',
	Loom: 'loom',
	OneDrive: 'onedrive',
	Dropbox: 'dropbox',
	Slack: 'slack',
	Sharepoint: 'sharepoint',
	Teams: 'teams',
	GoogleCalendar: 'google-calendar',
	OutlookCalendar: 'outlook-calendar',
	Gmail: 'gmail',
	Asana: 'asana',
	Zendesk: 'zendesk',
	Airtable: 'airtable',
	Notion: 'notion',
	Miro: 'miro',
	// New 3P? Add it here
} as const;

export const ThirdPartyConfigs = new Map<ProductKeys3P, ThirdPartyConfig>([
	[ProductKeys3P.AtlassianWebCrawler, atlassianWebCrawlerConfig],
	[ProductKeys3P.Figma, figmaConfig],
	[ProductKeys3P.Github, githubConfig],
	[ProductKeys3P.GoogleDrive, googleDriveConfig],
	[ProductKeys3P.Sharepoint, sharepointConfig],
	[ProductKeys3P.Loom, loomConfig],
	[ProductKeys3P.OneDrive, oneDriveConfig],
	[ProductKeys3P.Dropbox, dropboxConfig],
	[ProductKeys3P.Slack, slackConfig],
	[ProductKeys3P.Teams, teamsConfig],
	[ProductKeys3P.GoogleCalendar, googleCalendarConfig],
	[ProductKeys3P.Gmail, gmailConfig],
	[ProductKeys3P.OutlookCalendar, outlookCalendarConfig],
	[ProductKeys3P.Asana, asanaConfig],
	[ProductKeys3P.Zendesk, zendeskConfig],
	[ProductKeys3P.Airtable, airtableConfig],
	[ProductKeys3P.Notion, notionConfig],
	[ProductKeys3P.Miro, miroConfig],
	// New 3P? Add it here
]);

type ProductKeys3P = (typeof ProductKeys3P)[keyof typeof ProductKeys3P];

const ProductsOrder3P: ProductKeys3P[] = [
	// // Loom is actually a 1P so goes to the top
	ProductKeys3P.Loom,

	// Eventually this might be "smart"
	// For now this static order isn't strongly defined
	ProductKeys3P.GoogleDrive,
	ProductKeys3P.Sharepoint,
	ProductKeys3P.OneDrive,
	ProductKeys3P.Dropbox,
	ProductKeys3P.Slack,
	ProductKeys3P.Teams,
	ProductKeys3P.Github,
	ProductKeys3P.Figma,
	ProductKeys3P.GoogleCalendar,
	ProductKeys3P.Gmail,
	ProductKeys3P.OutlookCalendar,
	ProductKeys3P.Airtable,
	ProductKeys3P.Asana,
	ProductKeys3P.Zendesk,
	ProductKeys3P.Miro,
	// New 3P? Add it here

	// Always last
	ProductKeys3P.AtlassianWebCrawler,
];

/**
 * DO NOT import this directly
 *
 * Please use `import { ProductKeys3P } from 'src/common/constants/products.tsx'`
 */
export const DO_NOT_IMPORT_ProductKeys3P = ProductKeys3P;

/**
 * DO NOT import this directly
 *
 * Please use `import { ProductsOrder } from 'src/common/constants/products.tsx'`
 */
export const DO_NOT_IMPORT_ProductsOrder3P = ProductsOrder3P;
