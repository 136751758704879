import React from 'react';

import { token } from '@atlaskit/tokens';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import { CardSizes } from '../../linkCardsTypes';

import type { CommonCardProps } from './commonCardProps';
import { EmptyAndSmallCardWrapper } from './cardComponentsStyles';

const placeholderBackgroundColor = token('color.background.accent.gray.subtlest');

const wrapper = xcss({
	borderRadius: 'border.radius.200',
	border: `1px solid ${token('color.border')}`,
});

const image = xcss({
	borderTopLeftRadius: 'border.radius.200',
	borderBottomLeftRadius: 'border.radius.200',
	background: placeholderBackgroundColor,
	width: '72px',
});

const title = xcss({
	background: placeholderBackgroundColor,
	width: '35px',
	height: '8px',
});

const block = xcss({
	padding: 'space.200',
	gap: 'space.150',
});

const blockText = xcss({
	flexDirection: 'column',
	gap: 'space.050',
	flex: '1 0 0',
});

const publishedDate = xcss({
	background: placeholderBackgroundColor,
	width: '100px',
	height: '8px',
});

const descriptionFirstLine = xcss({
	background: placeholderBackgroundColor,
	width: '50px',
	height: '8px',
});

const descriptionSecondLine = xcss({
	background: placeholderBackgroundColor,
	width: '60px',
	height: '8px',
});

export const SmallPlaceholderCard = ({ cardHeight }: Pick<CommonCardProps, 'cardHeight'>) => {
	return (
		<EmptyAndSmallCardWrapper
			cardHeight={cardHeight}
			size={CardSizes.SMALL}
			data-testId="small-placeholder-card"
		>
			<Flex xcss={wrapper}>
				<Box xcss={image} />
				<Box xcss={block}>
					<Flex xcss={blockText}>
						<Box xcss={title} />
						<Box xcss={publishedDate} />
						<Box xcss={descriptionFirstLine} />
						<Box xcss={descriptionSecondLine} />
					</Flex>
				</Box>
			</Flex>
		</EmptyAndSmallCardWrapper>
	);
};
