import React from 'react';

import { token } from '@atlaskit/tokens';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import type { CommonCardProps } from './commonCardProps';
import { MediumLargeCardWrapper } from './cardComponentsStyles';

const placeholderBackgroundColor = token('color.background.accent.gray.subtlest');

const wrapper = xcss({
	flexDirection: 'column',
	borderRadius: 'border.radius.200',
	border: `1px solid ${token('color.border')}`,
});

const image = xcss({
	borderTopLeftRadius: 'border.radius.200',
	borderTopRightRadius: 'border.radius.200',
	background: placeholderBackgroundColor,
	height: '220px',
});

const block = xcss({
	padding: 'space.200',
	gap: 'space.150',
});

const blockText = xcss({
	flexDirection: 'column',
	gap: 'space.075',
	flex: '1 0 0',
});

const title = xcss({
	background: placeholderBackgroundColor,
	width: '35px',
	height: '8px',
});

const publishedDate = xcss({
	background: placeholderBackgroundColor,
	width: '200px',
	height: '8px',
});

const descriptionFirstLine = xcss({
	background: placeholderBackgroundColor,
	width: '121px',
	height: '8px',
});

const descriptionSecondLine = xcss({
	background: placeholderBackgroundColor,
	width: '149px',
	height: '8px',
});

export const MediumPlaceholderCard = ({ cardHeight }: Pick<CommonCardProps, 'cardHeight'>) => {
	return (
		<MediumLargeCardWrapper cardHeight={cardHeight} data-testId="medium-placeholder-card">
			<Flex xcss={wrapper}>
				<Box xcss={image} />
				<Box xcss={block}>
					<Flex xcss={blockText}>
						<Box xcss={title} />
						<Box xcss={publishedDate} />
						<Box xcss={descriptionFirstLine} />
						<Box xcss={descriptionSecondLine} />
					</Flex>
				</Box>
			</Flex>
		</MediumLargeCardWrapper>
	);
};
