import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

import { CardSizes } from '../../linkCardsTypes';

const i18n = defineMessages({
	zeroResultsTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.no-results-title',
		defaultMessage: 'Nothing here yet. Check back soon.',
		description:
			'Placeholder title that is displayed in Smart Card when there are no results given the current filters',
	},
});

const containerStyles = xcss({
	display: 'flex',
	height: '312px',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: 'space.025',
	alignSelf: 'stretch',
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
});

const bodyStyles = xcss({
	background: token('color.background.accent.gray.subtlest'),
	height: '264px',
	width: '100%',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
});

const subtitleStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	justifyItems: 'center',
	padding: 'space.150',
	color: 'color.text.subtlest',
	textAlign: 'center',
	alignItems: 'center',
	gap: 'space.075',
	alignSelf: 'stretch',
	height: '48px',
	fontWeight: 'font.weight.regular',
	font: 'font.body.small',
});

const containerStylesSmall = xcss({
	width: '100%',
	height: '76px',
	flexDirection: 'row',
});

const bodyStylesSmall = xcss({
	width: '72px',
	height: '100%',
	minWidth: '72px',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: '0',
	borderBottomLeftRadius: 'border.radius.100',
	borderBottomRightRadius: '0',
});

const subtitleStylesSmall = xcss({
	height: '100%',
	textAlign: 'start',
});

type ZeroResultsCardProps = { cardSize: CardSizes };

export const ZeroResultsCard = ({ cardSize }: ZeroResultsCardProps) => {
	return cardSize === CardSizes.SMALL ? (
		<Box testId="zero-results-card-small" xcss={[containerStyles, containerStylesSmall]}>
			<Box xcss={[bodyStyles, bodyStylesSmall]} />
			<Box xcss={[subtitleStyles, subtitleStylesSmall]}>
				<FormattedMessage {...i18n.zeroResultsTitle} />
			</Box>
		</Box>
	) : (
		<Box testId="zero-results-card" xcss={containerStyles}>
			<Box xcss={bodyStyles} />
			<Box xcss={subtitleStyles}>
				<FormattedMessage {...i18n.zeroResultsTitle} />
			</Box>
		</Box>
	);
};
