import { isUnsplashImage } from '../../../shared-components/UnsplashSearch/unsplashAPI';

type getImageTypeToShowProps = {
	imageSrc?: string;
};

const externalSiteMatcher =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const getImageTypeToShow = ({ imageSrc }: getImageTypeToShowProps) => {
	const showUnsplashImage = isUnsplashImage(imageSrc);
	const showExternalSiteImage = !!imageSrc && externalSiteMatcher.test(imageSrc);
	const showCustomUploadedImage =
		!!imageSrc && !isUnsplashImage(imageSrc) && !showExternalSiteImage;

	return {
		showUnsplashImage,
		showExternalSiteImage,
		showCustomUploadedImage,
		hasImage: showUnsplashImage || showCustomUploadedImage,
	};
};
