import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Inline, Text, xcss } from '@atlaskit/primitives';
import ErrorIcon from '@atlaskit/icon/core/error';
import { token } from '@atlaskit/tokens';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

export const i18n = defineMessages({
	errorLoadingCardsPreamble: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.loading-cards-error-preamble',
		defaultMessage: 'We’re having trouble loading these cards.',
		description:
			'Footer message shown when there was an error fetching search results for dynamic cards.',
	},
	errorLoadingCardsSupport: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.loading-cards-error-support',
		defaultMessage: 'Reload to try again.',
		description:
			'Footer message shown when there was an error fetching search results for dynamic cards.',
	},
});

const inlineStyles = xcss({
	marginTop: 'space.150',
});

export const LoadingCardsError = ({ error }: { error: Error }) => {
	useEffect(() => {
		getMonitoringClient().submitError(error, {
			attribution: Attribution.COMPANY_HUB,
		});
	}, [error]);

	return (
		<Inline space="space.100" alignBlock="center" xcss={inlineStyles}>
			<ErrorIcon label="error" color={token('color.icon.danger')} />

			<Text weight="medium" size="medium" color="color.text">
				<FormattedMessage {...i18n.errorLoadingCardsPreamble} />
			</Text>

			<Text weight="medium" size="medium" color="color.text.subtlest">
				<FormattedMessage {...i18n.errorLoadingCardsSupport} />
			</Text>
		</Inline>
	);
};
