import { z } from 'zod';

// Do not use z.coerce.boolean because JS type coercion from string to boolean is not logical
export const booleanSchema = z.enum(['true', 'false']).transform((x) => x === 'true');
export const commaSeparatedArraySchema = z.string().transform((x) => x.split(','));

// TODO QS-5444
// This should just be build from ProductKeys
export const productKeySchema = z.nativeEnum({
	Asana: 'asana',
	Atlas: 'townsquare',
	Bitbucket: 'bitbucket',
	Compass: 'compass',
	Confluence: 'confluence',
	GoogleDrive: 'drive',
	Jira: 'jira',
	JiraProductDiscovery: 'jira-product-discovery',
	JiraServiceManagement: 'jira-servicedesk',
	Opsgenie: 'opsgenie',
	Sharepoint: 'sharepoint',
	Slack: 'slack',
	Trello: 'trello',
	AtlassianWebCrawler: 'atlassian-web-crawler',
	Teams: 'teams',
	Figma: 'figma',
	Github: 'github',
	Loom: 'loom',
	OneDrive: 'onedrive',
	GoogleCalendar: 'google-calendar',
	Dropbox: 'dropbox',
	Gmail: 'gmail',
	OutlookCalendar: 'outlook-calendar',
	Airtable: 'airtable',
	Zendesk: 'zendesk',
	Notion: 'notion',
	Miro: 'miro',
} as const);
export type ProductKeys = z.infer<typeof productKeySchema>;
export const ProductKey = productKeySchema.enum;

// TODO QS-5444
// This should be built from constants/filters
export const allContentTypeSchema = z.nativeEnum({
	All: 'all',
});
export type AllContentTypes = z.infer<typeof allContentTypeSchema>;
export const AllContentType = allContentTypeSchema.enum;

export const atlasContentTypeSchema = z.nativeEnum({
	Goal: 'goal',
	Learning: 'learning',
	Project: 'project',
	Tag: 'tag',
} as const);
export type AtlasContentTypes = z.infer<typeof atlasContentTypeSchema>;
export const AtlasContentType = atlasContentTypeSchema.enum;

export const asanaContentTypeSchema = z.nativeEnum({
	WorkItem: 'work-item',
} as const);

export type AsanaContentType = z.infer<typeof asanaContentTypeSchema>;
export const AsanaContentType = asanaContentTypeSchema.enum;

export const confluenceContentTypeSchema = z.nativeEnum({
	Answer: 'answer',
	Attachment: 'attachment',
	Blogpost: 'blogpost',
	Calendar: 'calendar',
	Comment: 'comment',
	Database: 'database',
	Document: 'document',
	Embed: 'embed',
	Folder: 'folder',
	Page: 'page',
	Question: 'question',
	Space: 'space',
	SpaceCalendar: 'space-calendar',
	Whiteboard: 'whiteboard',
} as const);
export type ConfluenceContentTypes = z.infer<typeof confluenceContentTypeSchema>;
export const ConfluenceContentType = confluenceContentTypeSchema.enum;

export const googleContentTypeSchema = z.nativeEnum({
	Document: 'document',
	Presentation: 'presentation',
	Spreadsheet: 'spreadsheet',
} as const);
export type GoogleContentTypes = z.infer<typeof googleContentTypeSchema>;
export const GoogleContentType = googleContentTypeSchema.enum;

export const microsoftContentTypeSchema = z.nativeEnum({
	Document: 'document',
	Presentation: 'presentation',
	Spreadsheet: 'spreadsheet',
} as const);
export type MicrosoftContentTypes = z.infer<typeof microsoftContentTypeSchema>;
export const MicrosoftContentType = microsoftContentTypeSchema.enum;

export const dropboxContentTypeSchema = z.nativeEnum({
	Document: 'document',
	Presentation: 'presentation',
	Spreadsheet: 'spreadsheet',
} as const);
export type DropboxContentTypes = z.infer<typeof dropboxContentTypeSchema>;
export const DropboxContentType = dropboxContentTypeSchema.enum;

export const jiraContentTypeSchema = z.nativeEnum({
	Project: 'project',
	Issue: 'issue',
	Filter: 'filter',
	Dashboard: 'dashboard',
	Board: 'board',
} as const);
export type JiraContentTypes = z.infer<typeof jiraContentTypeSchema>;
export const JiraContentType = jiraContentTypeSchema.enum;

export const compassContentTypeSchema = z.nativeEnum({
	Component: 'component',
} as const);
export type CompassContentTypes = z.infer<typeof compassContentTypeSchema>;
export const CompassContentType = compassContentTypeSchema.enum;

export const trelloContentTypeSchema = z.nativeEnum({
	Board: 'board',
	Card: 'card',
} as const);
export type TrelloContentTypes = z.infer<typeof trelloContentTypeSchema>;
export const TrelloContentType = trelloContentTypeSchema.enum;

export const slackContentTypeSchema = z.nativeEnum({
	Message: 'message',
	Conversation: 'conversation',
} as const);
export type SlackContentTypes = z.infer<typeof slackContentTypeSchema>;
export const SlackContentType = slackContentTypeSchema.enum;

export const teamsContentTypeSchema = z.nativeEnum({
	Message: 'message',
	Conversation: 'conversation',
} as const);
export type TeamsContentTypes = z.infer<typeof teamsContentTypeSchema>;
export const TeamsContentType = teamsContentTypeSchema.enum;
export const githubContentTypeSchema = z.nativeEnum({
	Repository: 'repository',
	PullRequest: 'pull-request',
	Commit: 'commit',
	Branch: 'branch',
} as const);

export type GithubContentType = z.infer<typeof githubContentTypeSchema>;
export const GithubContentType = githubContentTypeSchema.enum;

export const webPageContentTypeSchema = z.nativeEnum({
	WebPage: 'web-page',
} as const);
export type WebPageContentTypes = z.infer<typeof webPageContentTypeSchema>;
export const WebPageContentType = webPageContentTypeSchema.enum;

export const figmaContentTypeSchema = z.nativeEnum({
	Design: 'design',
} as const);
export type FigmaContentTypes = z.infer<typeof figmaContentTypeSchema>;
export const FigmaContentType = figmaContentTypeSchema.enum;

export const loomContentTypeSchema = z.nativeEnum({
	Video: 'video',
} as const);
export type LoomContentTypes = z.infer<typeof loomContentTypeSchema>;
export const LoomContentType = loomContentTypeSchema.enum;

export const zendeskContentTypeSchema = z.nativeEnum({
	WorkItem: 'work-item',
} as const);
export type ZendeskContentType = z.infer<typeof zendeskContentTypeSchema>;
export const ZendeskContentType = zendeskContentTypeSchema.enum;

export const contentTypeSchema = z.nativeEnum({
	...AllContentType,
	...AsanaContentType,
	...AtlasContentType,
	...ConfluenceContentType,
	...GoogleContentType,
	...MicrosoftContentType,
	...JiraContentType,
	...CompassContentType,
	...TrelloContentType,
	...SlackContentType,
	...TeamsContentType,
	...FigmaContentType,
	...GithubContentType,
	...LoomContentType,
	...WebPageContentType,
	...DropboxContentType,
	...ZendeskContentType,
} as const);

export type ContentTypes = z.infer<typeof contentTypeSchema>;
export const ContentType = contentTypeSchema.enum;

export const lastModifiedValueSchema = z.nativeEnum({
	Anytime: 'anytime',
	Today: 'today',
	Yesterday: 'yesterday',
	Past7Days: 'past7Days',
	Past30Days: 'past30Days',
	PastYear: 'pastYear',
	Custom: 'custom',
} as const);
export const LastModified = lastModifiedValueSchema.enum;
export type LastModifiedValue = z.infer<typeof lastModifiedValueSchema>;

export const searchQueryParamsBaseSchema = z.object({
	text: z.optional(z.string().max(200)), // TODO: >>>> Ask how long should be the max query string?
	originalQuery: z.optional(z.string().max(200)), // Used to support "Did you mean" feature
	product: z.optional(z.string().pipe(productKeySchema)),
	type: z.optional(z.string().pipe(contentTypeSchema)),

	// Last Modified
	lastModified: z.optional(z.string().pipe(lastModifiedValueSchema)),
	lastModified_lt: z.optional(z.string().pipe(z.coerce.date())),
	lastModified_gt: z.optional(z.string().pipe(z.coerce.date())),
	from: z.optional(z.string()),
	to: z.optional(z.string()),

	titleMatch: z.optional(booleanSchema),

	// Confluence filters
	contributors: z.optional(commaSeparatedArraySchema),
	owners: z.optional(commaSeparatedArraySchema),
	ancestor: z.optional(z.string()),
	spaces: z.optional(commaSeparatedArraySchema),
	verified: z.optional(booleanSchema),
	labels: z.optional(commaSeparatedArraySchema),

	// Jira Filters
	jAssignee: z.optional(commaSeparatedArraySchema),
	jProject: z.optional(commaSeparatedArraySchema),

	referrer: z.optional(z.string()),
});

export type SearchQueryStringParams = z.infer<typeof searchQueryParamsBaseSchema>;
export const SearchQueryStringParamKey = searchQueryParamsBaseSchema.keyof().enum;
export type SearchQueryStringParamKeys = keyof SearchQueryStringParams;
export type SearchQueryStringParamsRaw = z.input<typeof searchQueryParamsBaseSchema>;
type ExtractKeysWithArrayValues<T> = {
	[P in keyof T]: T[P] extends unknown[] ? P : never;
}[keyof T];
export type SearchQueryStringParamKeysWithValues = NonNullable<
	ExtractKeysWithArrayValues<SearchQueryStringParams>
>;

export const searchQueryParamsValidators = [
	(queryParams) => {
		if (queryParams.lastModified !== 'custom') {
			const { lastModified_gt, lastModified_lt, ...restQueryParams } = queryParams;
			return restQueryParams;
		}
		return queryParams;
	},
	(queryParams) => {
		const { lastModified_lt, lastModified_gt } = queryParams;
		if (lastModified_lt && lastModified_gt && lastModified_lt > lastModified_gt) {
			const { lastModified, lastModified_gt, lastModified_lt, ...restQueryParams } = queryParams;
			return restQueryParams;
		}
		return queryParams;
	},
] satisfies ((queryParams: SearchQueryStringParams) => SearchQueryStringParams)[];

export const filterKeysSchema = z.nativeEnum({
	...ProductKey,
	...ContentType,
	...LastModified,
	...SearchQueryStringParamKey,
} as const);
export const FilterKey = filterKeysSchema.enum;
export type FilterKeys = z.infer<typeof filterKeysSchema>;
export type FilterKeysRaw = boolean | readonly string[];
