import { CardSizes } from '../linkCardsTypes';
import type { LinkCardsParameters, Card } from '../linkCardsTypes';
import { DEFAULT_CARDS_SIZE } from '../LinkCardsConfigPanel/components/Fields/SizeField';

const checkIsParameterValueValid = (parameter: any, expectedValues: any[]) => {
	return expectedValues.includes(parameter) ? parameter : null;
};

const checkSize = (size: CardSizes) =>
	checkIsParameterValueValid(size, [
		CardSizes.EXTRA_SMALL,
		CardSizes.SMALL,
		CardSizes.MEDIUM,
		CardSizes.LARGE,
		CardSizes.HERO,
	]);

const validateCardsUrls = (cards: Card[]) => {
	cards.forEach((card) => {
		if (!!card?.link && !card?.link.startsWith('http')) {
			card.link = '';
		}
	});
};

export const checkCards = (cards?: Card[]) => {
	if (!cards || cards?.length === 0) {
		return null;
	} else if (Array.isArray(cards)) {
		validateCardsUrls(cards);
		return cards;
	}
};

export const useValidateRequiredParameters = (currentParameters: any): LinkCardsParameters => {
	const validatedSize = checkSize(currentParameters?.size);
	const validatedCards = checkCards(currentParameters?.cards);

	if (!!validatedSize && !!validatedCards) return currentParameters;

	const validatedRequiredParameters = {
		size: validatedSize || DEFAULT_CARDS_SIZE,
		cards: validatedCards || [],
	} as LinkCardsParameters;

	return {
		...currentParameters,
		...validatedRequiredParameters,
	} as LinkCardsParameters;
};
