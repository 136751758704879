import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import {
	Card as SmartCard,
	MediaPlacement,
	PreviewBlock,
	SnippetBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';

import { markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { CardSizes } from '../../linkCardsTypes';
import type { OnCardFailed, OnCardSucceeded } from '../useCardsCombinedExperienceState';

import {
	EmptyAndSmallCardWrapper as EmptyCardWrapper,
	getTitleBlockStyles,
	CommonCardWrapperStyles,
	getCustomImageUploadStyles,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import { useSmartCardFallbackUrl } from './useSmartCardFallbackUrl';

type LinkCardsContentProps = {
	contentId: string;
	cardId: string;
	title?: string;
	imageSrc?: string;
	description?: string;
	size: CardSizes;
	cardHeight: number;
	isInViewMode: boolean;
	onCardSucceeded: OnCardSucceeded;
	onCardFailed: OnCardFailed;
	imageAltText?: string;
};

const i18n = defineMessages({
	emptyStatePlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.empty-state.placeholder-title',
		defaultMessage: 'Add a title',
		description: 'Placeholder title that is displayed in Smart Card when no title is provided',
	},
});

const previewStyles = {
	flexGrow: 1,
};

export const EmptyCard = ({
	contentId,
	cardId,
	size,
	title,
	description,
	imageSrc,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	imageAltText,
}: LinkCardsContentProps) => {
	const intl = useIntl();

	const handleResolve = useCallback(() => {
		onCardSucceeded(cardId);
	}, [onCardSucceeded, cardId]);

	const handleError = useCallback(
		(error: any) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMPANY_HUB,
			});
			markErrorAsHandled(error);
			onCardFailed(error);
		},
		[onCardFailed],
	);

	const url = useSmartCardFallbackUrl();

	const { showUnsplashImage, showCustomUploadedImage, hasImage } = getImageTypeToShow({
		imageSrc,
	});

	const getSmartCardSize = useCallback(() => {
		switch (size) {
			case CardSizes.SMALL:
			case CardSizes.MEDIUM:
				return SmartLinkSize.Medium;
			case CardSizes.LARGE:
			default:
				return SmartLinkSize.Large;
		}
	}, [size]);

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode} isEmptyCard>
			<EmptyCardWrapper
				size={size}
				data-testid="empty-state-visual-smart-card"
				cardHeight={cardHeight}
				isEmptyCard
				hasImage={hasImage}
			>
				<SmartCard
					id={cardId}
					onError={handleError}
					onResolve={handleResolve}
					appearance="inline"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: getSmartCardSize(),
					}}
					url={url}
				>
					{showUnsplashImage && (
						<PreviewBlock
							ignoreContainerPadding
							placement={size === CardSizes.SMALL ? MediaPlacement.Left : undefined}
							overrideCss={previewStyles as any}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock
							overrideCss={
								getCustomImageUploadStyles(
									size === CardSizes.SMALL ? MediaPlacement.Left : undefined,
								) as any
							}
						>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
								shouldShowErrorMessage={!(size === CardSizes.SMALL)}
							/>
						</CustomBlock>
					)}
					<TitleBlock
						text={title || intl.formatMessage(i18n.emptyStatePlaceholderTitle)}
						overrideCss={
							getTitleBlockStyles({
								disablePointerEvents: true,
								isSmallCard: size === CardSizes.SMALL,
								isDisabledTextColor: !title,
							}) as any
						}
						hideIcon
						anchorTarget="_self"
					/>
					{size !== CardSizes.SMALL && <SnippetBlock text={description} />}
				</SmartCard>
			</EmptyCardWrapper>
		</CommonCardWrapperStyles>
	);
};
