import type { FC } from 'react';
import React from 'react';
import times from 'lodash/times';

import { SmartCardProvider } from '@atlaskit/link-provider';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import {
	EDITOR,
	MacroExperienceFailure,
	MacroExperienceSuccess,
	RENDERER,
} from '@confluence/macro-tracker';

import type { LinkCardsParameters, Card } from '../linkCardsTypes';
import { CardSizes, RenderVariant } from '../linkCardsTypes';
import { linkCardsExtensionType } from '../linkCardsExtensionType';
import { CardSelectedToEditWrapper } from '../../shared-components/CardSelectedToEditWrapper';

import {
	EmptyCard,
	ExtraSmallCard,
	HeroCard,
	LargeCard,
	MediumCard,
	SmallCard,
	SmallPlaceholderCard,
	MediumPlaceholderCard,
	LargePlaceholderCard,
	ZeroResultsCard,
	LoadingCardsError,
} from './CardComponents';
import { CardsGrid } from './CardsGrid';
import { cardsDimensions } from './GridCardWrapper';
import { useCardsCombinedExperienceState } from './useCardsCombinedExperienceState';
import { useValidateRequiredParameters } from './useValidateRequiredParameters';
import { useCards } from './useCards';

type LinkCardsContentProps = {
	extensionLocalId: string;
	parameters: LinkCardsParameters;
	isInViewMode?: boolean;
	contentId: string;
	experienceName: string;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

const CARD_SIZE_MAP = {
	[CardSizes.EXTRA_SMALL]: ExtraSmallCard,
	[CardSizes.SMALL]: SmallCard,
	[CardSizes.MEDIUM]: MediumCard,
	[CardSizes.LARGE]: LargeCard,
	[CardSizes.HERO]: HeroCard,
};

const getCardComponent = (cardSize: CardSizes) => {
	return CARD_SIZE_MAP[cardSize] ?? MediumCard;
};

export const LinkCardsContent: FC<LinkCardsContentProps> = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
}) => {
	const validatedParameters = useValidateRequiredParameters(parameters);
	const { renderVariant, size } = validatedParameters;

	const isDynamicCards = renderVariant === RenderVariant.DYNAMIC;
	const cardHeight = cardsDimensions[size].height;
	const CardComponent = getCardComponent(size);

	return isDynamicCards ? (
		<DynamicCardsContent
			extensionLocalId={extensionLocalId}
			parameters={validatedParameters}
			isInViewMode={isInViewMode}
			contentId={contentId}
			experienceName={experienceName}
			createAnalyticsEvent={createAnalyticsEvent}
			cardHeight={cardHeight}
			CardComponent={CardComponent}
		/>
	) : (
		<ManualCardsContent
			extensionLocalId={extensionLocalId}
			parameters={validatedParameters}
			isInViewMode={isInViewMode}
			contentId={contentId}
			experienceName={experienceName}
			createAnalyticsEvent={createAnalyticsEvent}
			cardHeight={cardHeight}
			CardComponent={CardComponent}
		/>
	);
};

const ManualCardsContent = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
	cardHeight,
	CardComponent,
}) => {
	const { size, isAvatarShown, isPublishDateShown, cards } = parameters;
	const { areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed } =
		useCardsCombinedExperienceState(cards);

	return (
		<SmartCardProvider>
			<CardsGrid numberOfCards={cards.length} cardsSize={size}>
				{cards.map((card) => {
					// TODO CTE-2947 deconstruct card params and pass only what is necessary for each card size/type
					return !!card.link ? (
						<CardSelectedToEditWrapper
							key={card.cardId}
							cardId={card.cardId}
							extensionLocalId={extensionLocalId}
						>
							<CardComponent
								contentId={contentId}
								cardHeight={cardHeight}
								isAvatarShown={isAvatarShown}
								isPublishDateShown={isPublishDateShown}
								isInViewMode={!!isInViewMode}
								onCardSucceeded={onCardSucceeded}
								onCardFailed={onCardFailed}
								extensionType={linkCardsExtensionType}
								analyticsSource="cardsExtensionConfig"
								createAnalyticsEvent={createAnalyticsEvent}
								{...card}
							/>
						</CardSelectedToEditWrapper>
					) : (
						<CardSelectedToEditWrapper
							key={card.cardId}
							cardId={card.cardId}
							extensionLocalId={extensionLocalId}
						>
							<EmptyCard
								contentId={contentId}
								size={size}
								cardHeight={cardHeight}
								isInViewMode={!!isInViewMode}
								onCardSucceeded={onCardSucceeded}
								onCardFailed={onCardFailed}
								{...card}
							/>
						</CardSelectedToEditWrapper>
					);
				})}
			</CardsGrid>

			{areAllCardsLoaded && (
				<MacroExperienceSuccess
					name={experienceName}
					mode={isInViewMode ? RENDERER : EDITOR}
					contentId={contentId}
				/>
			)}
			{!!cardFailureError && (
				<MacroExperienceFailure
					name={experienceName}
					contentId={contentId}
					mode={isInViewMode ? RENDERER : EDITOR}
					error={cardFailureError}
					attributes={{}}
					source="LinkCardsContent"
				/>
			)}
		</SmartCardProvider>
	);
};

const PLACEHOLDER_CARD_SIZE_MAP = {
	[CardSizes.SMALL]: SmallPlaceholderCard,
	[CardSizes.MEDIUM]: MediumPlaceholderCard,
	[CardSizes.LARGE]: LargePlaceholderCard,
};

const getPlaceholderCardComponent = (cardSize: CardSizes) => {
	return PLACEHOLDER_CARD_SIZE_MAP[cardSize] ?? MediumPlaceholderCard;
};

const getDynamicCardsContentCards = ({
	parameters,
	createAnalyticsEvent,
	cardHeight,
	CardComponent,
	extensionLocalId,
	onCardSucceeded,
	onCardFailed,
	contentId,
	isInViewMode,
	cards,
	loading,
}: {
	parameters: LinkCardsParameters;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	cardHeight: number;
	CardComponent: FC<any>;
	extensionLocalId: string;
	onCardSucceeded: (cardId: string) => void;
	onCardFailed: (error: Error) => void;
	contentId: string;
	isInViewMode?: boolean;
	cards: Card[];
	loading: boolean;
}) => {
	const { size, isAvatarShown, isPublishDateShown, filters } = parameters;
	const PlaceholderCardComponent = getPlaceholderCardComponent(size);

	if (!filters) {
		return times(3, (index) => <ZeroResultsCard key={index} cardSize={size} />);
	}

	if (!cards.length || loading) {
		return times(3, (index) => <PlaceholderCardComponent key={index} cardHeight={cardHeight} />);
	}

	return cards.map((card: Card, index: number) => {
		if (!card.link) {
			return <ZeroResultsCard key={index} cardSize={size} />;
		}

		return (
			<CardSelectedToEditWrapper
				key={index}
				cardId={card.cardId}
				extensionLocalId={extensionLocalId}
			>
				<CardComponent
					contentId={contentId}
					cardHeight={cardHeight}
					isAvatarShown={isAvatarShown}
					isPublishDateShown={isPublishDateShown}
					isInViewMode={!!isInViewMode}
					onCardSucceeded={onCardSucceeded}
					onCardFailed={onCardFailed}
					extensionType={linkCardsExtensionType}
					analyticsSource="cardsExtensionConfig"
					createAnalyticsEvent={createAnalyticsEvent}
					{...card}
				/>
			</CardSelectedToEditWrapper>
		);
	});
};

const DynamicCardsContent = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
	cardHeight,
	CardComponent,
}) => {
	const { size } = parameters;
	const { cards, loading, error } = useCards(parameters);
	const { areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed } =
		useCardsCombinedExperienceState(cards);

	return (
		<SmartCardProvider>
			<CardsGrid numberOfCards={cards.length} cardsSize={size}>
				{getDynamicCardsContentCards({
					parameters,
					createAnalyticsEvent,
					cardHeight,
					CardComponent,
					extensionLocalId,
					onCardSucceeded,
					onCardFailed,
					contentId,
					isInViewMode,
					cards,
					loading,
				})}
			</CardsGrid>

			{error && <LoadingCardsError error={error} />}

			{areAllCardsLoaded && (
				<MacroExperienceSuccess
					name={experienceName}
					mode={isInViewMode ? RENDERER : EDITOR}
					contentId={contentId}
				/>
			)}
			{!!cardFailureError && (
				<MacroExperienceFailure
					name={experienceName}
					contentId={contentId}
					mode={isInViewMode ? RENDERER : EDITOR}
					error={cardFailureError}
					attributes={{}}
					source="LinkCardsContent"
				/>
			)}
		</SmartCardProvider>
	);
};
